/** @format */

import { createGlobalStyle, css } from 'styled-components';
export var layOutDesktopWidth = 769;
export var mobileNewDesignWidth = 581;
export var smallMobile = 350;
export var mobileHeaderHeight = 60.5;
export var mobileSearchHeight = 51;
export var pixel = function pixel(value) {
  return "".concat(value, "px");
};
export var colours = {
  qantasRed: '#E40000',
  qantasRedGradient: '#F70500',
  qantasRedEarth: '#D20000',
  qantasLightOrange: '#FCEBCD',
  qantasWarning: '#ED710B',
  qantasWarningHover: '#FCEBCD',
  qantasWariningFocus: '#ED7109',
  qantasWhite: '#FFFFFF',
  qantasVeryLightGrey: '#FAFAFA',
  qantasMediumGrey: '#F6F6F6',
  qantasLightGrey: '#DADADA',
  qantasMediumLightGrey: '#F4F5F6',
  qantasBorderGrey: '#cccccc',
  qantasBorderLightGrey: '#d9d9d9',
  qantasBorderMediumGrey: '#d8d8d8',
  qantasFontGrey: 'rgba(35,31,32,0.5)',
  qantasGrey: '#555555',
  qantasGrey40: '#666666',
  qantasDarkGrey: '#D4D4D4',
  qantasGrey60: '#4D4D4D',
  qantasDarkerGrey: '#333333',
  qantasCharcoal: '#323232',
  qantasSteel: '#4A4A4A',
  qantasIceWhite: '#F5F5F5',
  qantasLightBlue: '#90e2df',
  qantasAqua: '#BFF5F3',
  qantasLightAqua: '#D8EFEE',
  qantasGray91: '#E8E8E8',
  qantasLightGreen: '#4d8b38',
  qantasLightAmber: '#f3740c',
  qantasListBorder: '#979797',
  qantasListTab: '#f7f7f7',
  qantasLightCyan: '#8de2e0',
  qantasAquaqantasGrey: '#dddddd',
  webChatShadow: 'rgba(0,0,0,.35)',
  calloutGrey: '#d1d1d1',
  calloutPink: '#F7BBBC',
  qantasActiveGreen: '#66aa4f',
  qantasBlack: '#000000',
  qantasBlack10: '#231F20',
  qantasBlack60: '#666666',
  qantasPlaceHolderGrey: '#757575',
  qantasPlaceHolderLightGrey: '#999999',
  qantasTeal: '#BFF4F2'
};
export var fontSizes = {
  xxxl: '1.75rem',
  // 28px
  xxl: '1.5rem',
  // 24px
  xl1: '1.375rem',
  // 22px
  xl: '1.25rem',
  // 20px
  large: '1.125rem',
  // 18px
  base: '1rem',
  // 16px
  small: '.875rem',
  // 14px
  xs: '.75rem',
  // 12px
  xxs: '.5rem',
  // 8px
  xxxs: '.375rem' // 6px
};
export var headingSizes = {
  h0: '3rem',
  // 48px
  h1: '2.25rem',
  // 36px
  h3: '1.375rem' // 22px
};
export var customFonts = {
  qsIcons: 'qs-icons',
  ciutadellaReg: 'Ciutadella-Regular',
  ciutadellaMed: 'Ciutadella-Medium'
};
export var cardSize = {
  desktopWidth: '594px',
  desktopHeight: '260px',
  mobileWidth: '290px',
  mobileHeight: '310px'
};
export var layoutSizes = {
  containerWidth: '970px',
  containerWideWidth: '1170px',
  desktopWidth: "".concat(layOutDesktopWidth, "px"),
  ipadPortraitWidth: '991px',
  toasterStyleBreakPoint: '660px',
  mobileWidth: "".concat(mobileNewDesignWidth, "px"),
  tabletAndDesktopWidth: "".concat(mobileNewDesignWidth + 1, "px"),
  containerVeryWideWidth: '1248px'
};
export var navbarSizes = {
  navbarMode: '1071px',
  dropdownMode: '1070px'
};
export var gradients = {
  greyToWhite: 'linear-gradient(to left, #ffffff, #e8e8e8);',
  whiteToGray: 'linear-gradient(to right, #ffffff, #e8e8e8);'
};

/**
 * Define z-index
 */
var zIndexAbove = 1;
var zIndexBelow = -1;
export var zIndexes = {
  modal: 99999,
  mobileHeader: 30,
  desktopHeader: 30,
  above: 15,
  // above other components and below mobile sticky header and search bar
  base: 0
};

// mobile header
zIndexes.mobileMenu = zIndexes.mobileHeader + zIndexAbove;
zIndexes.mobileSearch = zIndexes.mobileHeader + zIndexBelow;
zIndexes.toaster = zIndexes.mobileSearch + zIndexBelow;

// desktop header
zIndexes.desktopNavItemList = zIndexes.desktopHeader + zIndexAbove;
zIndexes.desktopProfile = zIndexes.desktopHeader + 5; // profile button at top right
zIndexes.desktopProfileDropdown = zIndexes.desktopProfile;

// category page
zIndexes.categoryFilter = zIndexes.above;
zIndexes.favouriteCallout = zIndexes.base + zIndexAbove;

// homepage
zIndexes.favouritesOnBonusBanner = zIndexes.above + zIndexAbove;
zIndexes.tooltip = zIndexes.base + zIndexAbove;

// All-stores page
zIndexes.backToTopBtn = zIndexes.above;
zIndexes.alphbetNav = zIndexes.above; // for both mobile and desktop
zIndexes.alphbetNavMobile = zIndexes.above; // for both mobile and desktop

// card-offers page
zIndexes.filterSection = zIndexes.above;

// campaign page (/category/offers-and-more)
zIndexes.campaignNavbar = zIndexes.above;

// partner-host page (/partner-host?id=null&ct=link) or join
zIndexes.edrModalWrapper = zIndexes.modal + zIndexBelow;
export var responsiveQuery = {
  desktop: "(min-width: ".concat(layOutDesktopWidth, "px)"),
  mobile: "(max-width: ".concat(layOutDesktopWidth - 1, "px)"),
  tablet: "(min-width: ".concat(mobileNewDesignWidth, "px) and (max-width: 990px)"),
  mobileNew: "(max-width: ".concat(mobileNewDesignWidth, "px)"),
  // mobile new design break points
  smallMobile: "(max-width: ".concat(smallMobile, "px)"),
  // mobile new design break points
  largerThanMobileNew: "(min-width: ".concat(mobileNewDesignWidth + 1, "px)"),
  mobileAndTablet: '(max-width: 990px)',
  tabletAndDesktop: '(min-width: 991px)'
};
export var pageTitlePaddingTopForMobile = css(["@media only screen and (max-width:", "){padding-top:1.375rem;}"], layoutSizes.mobileWidth);
export var GlobalStyles = createGlobalStyle(["html,body{width:100%;height:100%;}body{margin:0;font-family:", ",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif;-webkit-font-smoothing:antialiased;overflow-x:hidden;& > div:first-child{height:100%;}*{box-sizing:border-box;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}button{color:", ";}}.menu-opened{overflow-y:hidden;}main{width:100%;margin:0 auto;position:relative;padding:0;@media only screen and ", "{margin-top:", "}@media only screen and ", "{margin-top:0;}}.content{height:100%;}.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0;}.howItWorksToolTip.place-top{z-index:", " !important;&::after{left:38% !important;}}a{color:", ";}a.mobileToolTipLink{color:", " !important;font-family:", " !important;font-size:", " !important;text-decoration:underline !important;}#enrolment-form{width:100%!important;.section-body{padding:2rem;background:", ";}.enrollment-widget-result-container{width:400px;min-height:300px;margin:auto;padding:2rem 0;.enrollment-widget-header{display:flex;align-items:center;margin-bottom:1rem;.enrollment-instructions{padding-left:1rem;p{font-size:", ";margin:0;}}}.enrollment-widget-result{text-align:center;p{font-size:", ";}}}.enrollment-terms-container{.terms-title{padding:0;background:", ";text-align:center;a{display:block;margin:0;padding:0.5rem 1rem;background:", ";color:", ";font-size:", ";font-weight:500;text-transform:uppercase;border-radius:4px;z-index:4;box-shadow:0 2px 2px 0 rgba(0,0,0,0.1);}i{font-size:6px;margin-left:10px;&:before{margin-top:-0.4rem;}}}.enrollment-terms{background-color:", ";padding:0 2.75rem;}}}"], customFonts.ciutadellaReg, colours.qantasBlack, responsiveQuery.mobileNew, function (_ref) {
  var fullView = _ref.fullView;
  if (!fullView) {
    return '0';
  }
  return pixel(mobileHeaderHeight);
}, responsiveQuery.largerThanMobileNew, zIndexes.tooltip, colours.qantasRed, colours.qantasCharcoal, customFonts.ciutadellaReg, fontSizes.small, colours.qantasWhite, fontSizes.base, fontSizes.base, colours.qantasWhite, colours.qantasWhite, colours.qantasCharcoal, fontSizes.small, colours.qantasWhite);
export var baseTheme = {
  name: 'qantas',
  colours: colours,
  headingSizes: headingSizes,
  fontSizes: fontSizes,
  customFonts: customFonts,
  responsiveQuery: responsiveQuery,
  cardSize: cardSize
};